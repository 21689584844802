<template>
  <div class="about container">
    <playme-logo/>
    <playerLoaderSvg></playerLoaderSvg>
    <h1>Heading default h1</h1>
    <h2>Heading default h2</h2>
    <h3>Heading default h3</h3>
    <p>Lorem paragraph ipsum dolor sit amet, <a href="https://vuejs.org" target="_blank" rel="noopener">Core Docs</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p>
    <ul>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
      <li><a href="https://playme.mx" target="_blank" rel="noopener">List item link</a></li>
    </ul>
    <p>✨ 🚀 🎉 🚨 🚧 📦️ 🐛 💻 🗃 💫 🐞 🔗 🐙 🎮 🦄 💊 🍻 🔊 📻 🎵 🎶</p>
    <div>
        <unicon name="equal-circle"></unicon>
        <unicon name="link"></unicon>
        <unicon name="setting"></unicon>
        <unicon name="favorite"></unicon>
        <unicon name="user"></unicon>
        <unicon name="lock-alt"></unicon>
        <unicon name="lock-open-alt"></unicon>
        <unicon name="clock"></unicon>
        <unicon name="stopwatch"></unicon>
        <unicon name="stopwatch-slash"></unicon>
        <unicon name="mountains"></unicon>
        <unicon name="mountains-sun"></unicon>
        <unicon name="qrcode-scan"></unicon>
        <unicon name="shopping-cart"></unicon>
        <unicon name="shopping-basket"></unicon>
        <unicon name="previous"></unicon>
				<unicon name="play"></unicon>
				<unicon name="pause"></unicon>
				<unicon name="step-forward"></unicon>
        <unicon name="previous"></unicon>
        <unicon name="volume"></unicon>
        <unicon name="volume-down"></unicon>
        <unicon name="volume-mute"></unicon>
        <unicon name="forward"></unicon>
        <unicon name="backward"></unicon>
				<unicon name="play-circle"></unicon>
				<unicon name="pause-circle"></unicon>
				<unicon name="stop-circle"></unicon>
				<unicon name="angle-down"></unicon>
				<unicon name="angle-up"></unicon>
        <unicon name="headphones"></unicon>
        <unicon name="bluetooth-b"></unicon>
        <unicon name="heart-sign"></unicon>
        <unicon name="heart"></unicon>
        <unicon name="music"></unicon>
        <unicon name="music-note"></unicon>
        <unicon name="music-tune-slash"></unicon>
        <unicon name="sync"></unicon>
        <unicon name="sync-slash"></unicon>
        <unicon name="compact-disc"></unicon>
        <unicon name="icons"></unicon>
        <unicon name="no-entry"></unicon>
        <unicon name="traffic-barrier"></unicon>
        <unicon name="paint-tool"></unicon>
        <unicon name="palette"></unicon>
        <unicon name="airplay"></unicon>
        <unicon name="closed-captioning"></unicon>
        <unicon name="brightness"></unicon>
        <unicon name="sun"></unicon>
        <unicon name="sunset"></unicon>
        <unicon name="moon"></unicon>
        <unicon name="moonset"></unicon>
        <unicon name="bug"></unicon>
        <unicon name="pathfinder"></unicon>
        <unicon name="compact-disc"></unicon>
        <unicon name="picture"></unicon>
        <unicon name="comment-info"></unicon>
        <unicon name="circle"></unicon>
        <unicon name="square"></unicon>
        <unicon name="triangle"></unicon>
        <unicon name="fire"></unicon>
        <unicon name="message"></unicon>
        <unicon name="share-alt"></unicon>
        <unicon name="layers"></unicon>
        <unicon name="layers-slash"></unicon>
        <unicon name="layer-group"></unicon>
        <unicon name="layer-group-slash"></unicon>
        <unicon name="ellipsis-v"></unicon>
        <unicon name="repeat"></unicon>
        <unicon name="arrow-random"></unicon>
        <unicon name="bars"></unicon>
        <unicon name="apps"></unicon>
        <unicon name="list-ul"></unicon>
        <unicon name="robot"></unicon>
        <unicon name="twitter" icon-style="monochrome"></unicon>
        <unicon name="cloud-wifi"></unicon>
        <unicon name="github-alt"></unicon>
        <unicon name="instagram" icon-style="monochrome"></unicon>
        <unicon name="facebook-f"></unicon>
        <unicon name="reddit-alien-alt" icon-style="monochrome"></unicon>
        <unicon name="paypal" icon-style="monochrome"></unicon>
        <unicon name="snapchat-ghost" icon-style="monochrome"></unicon>
        <unicon name="rocket"></unicon>
        <unicon name="envelope"></unicon>
        <unicon name="constructor"></unicon>
        <unicon name="brain"></unicon>
        <unicon name="android" icon-style="monochrome"></unicon>
        <unicon name="android-alt"></unicon>
        <unicon name="apple-alt"></unicon>
        <unicon name="apple"></unicon>
        <unicon name="shield-check"></unicon>
    </div>
  </div>
</template>

<script>
import PlaymeLogo from "../components/logo/playme-logo";
import playerLoaderSvg from "../components/player/player-loader-svg"
export default {
  name: 'Ux',
  components:{
    PlaymeLogo,
    playerLoaderSvg
  }
}
</script>

<style scoped lang="scss">
.about .logo{
  justify-content: center;
}
</style>
